@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');

/* @font-face {
    font-family: 'lasticaregular';
    src: url('fontsfree-net-lastica-webfont.woff2') format('woff2'), url('fontsfree-net-lastica-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */

:root {
  --primary: #fc6600;
  --secondary: #FEA366;
  --dark: #262626;
  --light: #eaeaea;
  --gray: #535353;
  --input-gray: #d9d9d9;
  --white: #fff
}
/* COMMON */
h1,h2,h3 {
  margin: 0;
  font-weight: 600;
}
h2 {
  font-size: 18px;
  text-align: center;
}
strong {
  color: var(--primary);
  font-weight: inherit;
}
body {
  margin: 0;
  color: var(--dark);
  font-family: 'Montserrat';
  background: var(--light);
}
footer {
  padding: 20px 20px;
  background-color: var(--dark);
  color: var(--white);
  text-align: center;
}
footer img {
  width: 40%;
  margin-bottom: 20px;
}
.page {
  margin: 0 auto;
  max-width: 800px;
  min-height: 75vh;
}
.spacer {
  margin-bottom: 20px;
}
.super-spacer {
  margin-bottom: 40px;
}
.mini-spacer {
  margin-bottom: 13px;
}
.important-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase
}
section {
  margin-bottom: 60px;
  padding: 0 20px;
  text-align: center;
}


ul {
  text-align: start;
  padding-left: 20px;
}
ul > li {
  margin-bottom: 15px;
}

.cta {
  font-family: 'Montserrat';
  width: 100%;
  padding: 15px 20px;
  border-radius: 6px;
  outline: none;
  border: 3px solid var(--secondary);
  background-color: var(--primary);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}
button:disabled,
button[disabled]{
  border: 3px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
/* NAV */
nav {
  padding: 20px 20px;
  margin-bottom: 20px;
  text-align: center;
}
nav img {
  width: 50%;
}
/* ------------------- HOME PAGE */
/* DARK SECTION */
.dark-section {
  background-color: var(--dark);
  color: var(--white);
  padding: 30px 20px;
  text-align: center;
}
.video-img {
  width: 100%;
  outline: 2px solid var(--primary);
}
/* SWIPER  */
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-pagination {
  padding-top: 40px;
}

/* FORM */
form {
  margin-bottom: 40px;
  padding: 0 20px;
}
form .input {
  display: flex;
  flex-direction: column;
}
label {
  font-weight: 500;
}
input {
  width: 92.5%;
  border-radius: 6px;
  background-color: var(--input-gray);
  outline: none;
  border: none;
  padding: 12px 13px;
  margin-bottom: 20px;
}
input::placeholder {
  font-weight: 600;
  color: #adadad;
  font-family: 'Montserrat';
}
.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.checkbox input {

  width: 5%;
  margin-bottom: 0;
}
.checkbox div {
  font-size: 13px;
}
